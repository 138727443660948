@import 'styles/extends/_generics';

$list-file-radius: 5px;

.tr__add-link-task {
    .width-link-task-modal {
        width: 100%;
    }
    .tr__steps-breadcrum {
        position: relative;
        width: 100%;
        justify-content: center;

        &:after {
            content: '';
            position: absolute;
            bottom: -$modal-padding;
            left: -$modal-padding;
            width: calc(100% + #{$modal-padding * 2});
            border-top: 1px solid $border-gray;
        }

        .step {
            min-width: 33%;
        }
    }

    .tr__modal--content--wrapper {
        padding: 0 !important;

        > .tr__add-link-task__form {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px;

            > div.tr__add-partners-form {
                width: 100%;
                margin-top: 40px;
            }

            > div.tr__verify-details-form {
                width: 100%;
                margin-top: 40px;
            }
    
            .tr__radio-group--wrapper {
                .tr__radio-group {
                    .tr__form-radio {
                        width: 33%;
                    }
                }
                
                p {
                    line-height: 23px;
                }
            }
    
            .tr__add-link__list-file {
                border: 1px solid $breadcrum-gray;
                border-radius: $list-file-radius;
            }
        }

        .tr__add-link-task__footer{
            @extend %section-footer;
    
            .tr__link {
                margin-right: 40px;
                color: $label-gray;
            }
        }
    }

    .template-chooser {
        &--header {
            h4 {
                @extend %large-label;
            }
        }
        
        &--content {
            .tr__dropdown {
                flex-grow: 1;
            }
        }
    }
}