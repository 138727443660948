@import 'styles/extends/_generics';

.tr__document-table {
    .tr__collapse {
        overflow: initial;
        .tr__collapse--content {
            padding: 0 10px;
        }

        &.active {
            .tr__collapse--content {
                padding: 10px;
            }
        }
    }
    .tr__document-table--header{
        color: $label-gray;
        font-weight: 500;
    }
    .table-row {
        .column {
            flex: 0 0 20%;
            color: $base-text-color;

            &:nth-child(2){
                flex: 0 0 25%;
            }

            &:nth-child(5) {
                flex: 0 0 15%;
            }
        }
    }
    
    &--content {
        border-top: 1px solid $button-hover-gray;
        .column {
            padding: 15px;

            .tr__dropdown,
            .tr__field {
                flex-grow: 1;
            }
            
            > i {
                @extend %close-icon;
            }

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }

    &--footer {
        padding: 20px 10px 10px 10px;
    }

    .invoiceNum .tr__field-input{
        background-color: #ededef;
    }

    .invoiceNum .tr__button {
        background-color: transparent;
        border-color: $base-text-color;
        color: $base-text-color;
        padding: 7px 20px;
        border-radius: 3px;
        font-size: 13px;
    }
    .hidden{
        display: none !important;
    }
}



.loading.active{
    opacity: 1;
}

.loading{
    opacity: 0;
}

.loading:after {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border: 4px solid #44BDFF;
    border-left-color: #ededef;
    border-right-color: #ededef;
    border-radius: 20px;
    animation: loading 1.5s linear infinite;
}