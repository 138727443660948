@import 'styles/extends/_generics';

$statues-width: 206px;
$width-shipment-creator: 150px;
.tr__details-header {
    .tr__statuses {
        width: $statues-width;
    }

    p {
        color: $label-gray;
    }

    .creator_shipment {
        display: flex;
        align-items:center;
        span {
            display: inline-block;
            width: $width-shipment-creator;
            // @extend %ellipse-text;
            text-align: right;
        }
        .tr__tooltip {
            &--content {
                display: flex;
            }
            &--popup {
                left: -50%;
            }
        }
    }
}