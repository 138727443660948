@import 'styles/extends/_generics';

$dot-size: 5px;
$icon-size: 32px;

.tr__active-shipment-item {
    @extend %card-box;
    padding: 0;
    overflow: visible !important;
    border-radius: 5px;
    position: relative;

    &--body {
        cursor: pointer;
    }

    &--header {
        .h4 {
            color: $white;
        }

        .status-tag {
            padding: 7px 12px;
            background-color: $label-gray;
            color: $white;
            font-size: $small-size;
            border-radius: 50px;
            margin-right: 10px;
            @extend %f-regular;

            &.complete {
                background-color: $main-blue;
            }
        }

        .note {
            margin-left: 15px;
            position: relative;
            flex-grow: 1;
            margin-left: 15px;
            position: relative;
            // width: 77%;
            
            > * {
                @include opacity(0.5);
            }

            i {
                margin-right: 5px;
            }
            span {
                // display: inline-block;
                // width: 60%;
                @extend %ellipse-text
            }
            .edit-form {
                @include centered-position(false, true);
                min-width: 40%;
                @include opacity(1);

                .tr__field {
                    width: 100%;
                    &-input {
                        background-color: transparent;
                        color: $white;
                        padding: 7px 0;
                    }
                }
    
                .tr__button {
                    background-color: transparent;
                    border-color: $white;
                    color: $white;
                    padding: 7px 20px;
                    border-radius: 3px;
                    font-size: $small-size;
                    @extend %f-regular;
                }
            }
        }

        .shipment {
            font-size: $small-size;
            .info {
                @include opacity(0.6);
                > i {
                    margin-right: 5px;
                }
            }
            span {
                &.no-of-product {
                    margin-left: 15px;
                    position: relative;

                    &:before {
                        content: '';
                        width: $dot-size;
                        height: $dot-size;
                        background-color: $border-gray;
                        border-radius: $dot-size;
                        @include centered-position(false, true);
                        left: -$dot-size * 2;
                    }
                }

                &.task-warning {
                    background-color: $white-30;
                    color: $bonus-yellow;
                    border-radius: 50px;
                    padding: 5px 8px;

                    i {
                        font-size: $h4;
                    }
                }
            }
        }
    }

    &--steps {
        padding: $modal-padding;
        > div {
            flex-grow: 1;
        }
    }

    &--footer {
        background-color: $light-gray;
        position: relative;
        &--icon {
            display: flex;
            padding: 5px 0;
            .bg-icon {
                @extend %step-icon;
                margin-left: 10px;
                width: $icon-size;
                height: $icon-size;
                font-size: $large-size;
                &.active {
                    background-color: $main-blue;
                }
            }
        }

        .tr__collapse {
            background-color: transparent;

            &--title {
                @extend %f-medium;
                justify-content: flex-start !important;
                padding: $content-padding;
                color: $label-gray;
                
                span {
                    @extend %enable-transition;
                }

                .toggle-btn {
                    margin-left: 8px;
                }
            }

            &.active {
                .tr__collapse--title {
                    color: $base-text-color;
                }
            }

            &--content {
                padding: 0 20px;
            }

            &.active {
                .tr__collapse--content {
                    padding: 12px 20px;
                }
            }
        }

        .tr__shipment-tags {
            position: absolute;
            right: 20px;
            top: 12px;
        }
    }

    &--star {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: $placeholder-gray;
        position: absolute;
        top: 0%;
        left: 0%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        &:hover {
            background-color: $pending-status-yellow;
            transition: all .4s ease;
        }
        i {
            color: $white;
        }

        &.active-star {
            background-color: $pending-status-yellow;
        }
    }

    .shipment-teams {
        padding: 8px 20px;
        
        i {
            @extend %step-icon;
            margin-left: 10px;
            width: $icon-size;
            height: $icon-size;
            font-size: $large-size;

            &.icon-truck {
                font-size: $h3;
            }

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .tr__link-shipment-create {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: $small-size;
        @extend %f-medium;
    }
}